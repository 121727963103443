// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Vue from 'vue'
import LoadScript from "vue-plugin-load-script";
import Axios from "axios"

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import ScopePay from '../tmpl/scope_onpay.vue'
import ScopeJob from '../tmpl/scope_job.vue'
// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
Vue.use(LoadScript);

// var carrier = Axios.get("http://:3000/");

document.addEventListener('DOMContentLoaded', () => {

    Rails.ajax({
        url: '/webservice/scope',
        type: "get",
        data: "",
        success: function(data){
            const scope_pay = new Vue({
                el: "#scope_on_pay",
                render: h => h(ScopeJob),
                data: { scope: data.onpay },
            });

            const scope_job = new Vue({
                el: "#scope_job",
                render: h => h(ScopePay),
                data: { scope: data.injob },
            });

        }
    })

})