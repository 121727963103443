<template>
  <div class="sidebar__block">
    <div>{{scope}}</div>
    <div>Неоплаченные перевозки</div>
  </div>
</template>

<script>
export default {
  name: "scope_job",
  data: function(){
    return {scope: this.$parent.scope }
  }
}
</script>

<style scoped>

</style>